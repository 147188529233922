<template>
  <div>
    <b-modal
      id="modal_bed_rawat_inap"
      size="lg"
      centered
      :title="`Informasi ${is_data.nama_bed}`"
      header-bg-variant="primary"
      header-text-variant="light"
      @show="showModal()"
    >
      <div class="">
        <b-row class="m-0 p-0">
          <b-col cols="3"><h6 class="m-0 p-0">Ruangan</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{is_data.nama_ruang}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Kamar/ Bed</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{is_data.nama_kamar}} - {{is_data.nama_bed}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Tgl Daftar</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{$moment(dataBed.tanggal_daftar).format('LL')}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Tgl Masuk</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{$moment(dataBed.tanggal_mulai).format('LL')}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Nama Pasien</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{dataBed.nama_lengkap}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Jenis Kelamin</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{dataBed.nama_jenis_kelamin}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Umur</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{dataBed.umur}}</p></b-col>
          <b-col cols="3"><h6 class="m-0 p-0">Asuransi</h6></b-col>
          <b-col cols="1" class="text-right">:</b-col>
          <b-col cols="8"><p class="m-0 p-0">{{dataBed.nama_asuransi}}</p></b-col>
        </b-row>
        <!-- <pre>{{dataBed}}</pre> -->
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal_bed_rawat_inap')">
          Close
        </b-button>
        <!-- <b-button variant="primary" @click="hapus()" :disabled="busy">
          {{ button }}
        </b-button> -->
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "modalDelete",
  props: ["is_data"],
  data() {
    return {
      busy: false,
      dataBed: {},
    };
  },
  methods: {
    async showModal(){
      const vm = this
      vm.busy = true
      try {
        await new Promise(resolve => setTimeout(resolve, 100));
        console.log('is data', vm.is_data)
        const dataBed = await vm.$axios.post('ms_ruang/details_by_ms_bed_id', {ms_bed_id: vm.is_data.ms_bed_id})
        console.log('dataBed', dataBed)
        vm.dataBed = {}
        if(dataBed.data.status == 200){
          vm.dataBed = dataBed.data.data[0]
          vm.dataBed.nama_asuransi = vm.dataBed.no_bpjs ? 'BPJS' : 'Umum' 
          vm.dataBed.nama_jenis_kelamin = vm.dataBed.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' 
          // umur
          let expired = vm.dataBed.tanggal_lahir ? vm.$moment(vm.dataBed.tanggal_lahir) : vm.$moment()
          let now = vm.$moment()
          let hasil = vm.$moment.duration(now.diff(expired))
          vm.dataBed.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`
        }
      } catch (error) {
        console.log(error)
        vm.$store.commit("set_alert", { variant: "warning", msg: 'Terjadi kesalahan system', showing: true });
      } finally {
        vm.busy = false
      }
    }
  },
};
</script>
