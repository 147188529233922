<template>
  <div style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0">
              Monitoring
            </h6>
          </div>
        </b-col>
        <!-- <b-col cols="12" md="12" lg="4" xl="4">
          <div style="display: flex; justify-content: flex-end">
            <b-button variant="primary  " @click="$bvModal.show('modal-pendaftaran')" :disabled="busy"> 
              <h6 class="m-0 p-0 text-light">
                <b-icon icon="list-task"></b-icon>
                    List Monitoring
                </h6>
            </b-button>
          </div>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row style="">
        <b-col cols="4" class="mb-3" v-for="(item, key) of listRuang" :key="key">
          <b-card class="h-100">
            <h5 class="custom-title-card"><strong>{{item.nama_ruang}}</strong></h5>
            <h5 class="mr-3 custom-title-card" style="color: black;">{{item.nama_kamar}}</h5>
            <div class="d-flex">
              <h6 class="mr-3 custom-title-card" style="color: black; font-size: 14px;">Tersedia : {{item.tersedia}}</h6>
              <h6 class="custom-title-card" style="font-size: 14px;">Terisi : {{item.terisi}}</h6>
            </div>
            <div class="d-flex flex-wrap">
              <div v-if="item.data_bed.length == 0" class="py-3"></div>
              <div 
                class="mr-2 mt-2" 
                v-for="(bed, keyBed) of item.data_bed" 
                :key="keyBed" 
                @click="is_data = bed, bed.status_bed == 2 ? $bvModal.show('modal_bed_rawat_inap') : null">
                <img 
                  src="/img/bed.svg" 
                  alt="" 
                  srcset="" 
                  :style="bed.status_bed == 2 ? 'height: 1.5rem; margin-right:5px; filter: invert(20%) sepia(100%) saturate(7500%) hue-rotate(350deg) brightness(90%) contrast(120%);' : 'height: 1.5rem; margin-right:5px;  filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(10deg) brightness(70%) contrast(100%);'"
                >
                  <!-- style="height: 1.5rem; margin-right:5px;  filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(10deg) brightness(70%) contrast(100%);" -->
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!-- <pre>{{listRuang}}</pre> -->
    </b-container>
    <ModalBed
      :is_data="is_data" 
    />
  </div>
</template>
<script>
import ModalBed from './modal_bed.vue'
export default {
  components: {
    ModalBed
  },
  data() {
    return {
      busy: false,
      is_data: {},
      listRuang: [],
    }
  },
  mounted() {
    const vm = this
    vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      try {
        const listRuang = await vm.$axios.post('ms_ruang/list_ruang')
        console.log('listRuang', listRuang)
        vm.listRuang = []
        if(listRuang.data.status == 200){
          // vm.listRuang = listRuang.data.data
          for (let x = 0; x < listRuang.data.data.length; x++) {
            const ruang = listRuang.data.data[x];
            console.log('ruang', x)
            if (ruang.data_kamar.length > 0) {
              for (let y = 0; y < ruang.data_kamar.length; y++) {
                const kamar = ruang.data_kamar[y];
                kamar.tersedia = 0
                kamar.terisi = 0
                console.log('kamar', y)
                for (let z = 0; z < kamar.data_bed.length; z++) {
                  const bed = kamar.data_bed[z];
                  bed.nama_kamar = kamar.nama_kamar
                  bed.nama_ruang = ruang.nama_ruang
                  if(bed.status_bed == 1){
                    kamar.tersedia++
                  }else{
                    kamar.terisi++
                  }
                }
                vm.listRuang.push({...kamar, nama_ruang: ruang.nama_ruang})
              }
            } else {
              vm.listRuang.push({
                tersedia: 0,
                terisi: 0,
                nama_ruang: ruang.nama_ruang,
                nama_kamar: '(belum ada kamar)',
                data_bed: [],
              })
            }
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
      // vm.listRuang = []
      // for (let x = 1; x <= 5; x++) {
      //   for (let y = 1; y <= 5; y++) {
      //     const bed = []
      //     for (let z = 1; z <= 12; z++) {
      //       bed.push({
      //         nama_bed: `Bed ${x}`
      //       })
      //     }
      //     vm.listRuang.push({
      //       nama_ruang: `Ruang ${x}`,
      //       nama_kamar: `Kamar ${y}`,
      //       tersedia: x,
      //       terisi: x,
      //       bed,
      //     })
      //   }
      // }
    },
  },
}
</script>